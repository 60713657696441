<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">
        {{ labels.new }}
      </v-btn>
    </v-card-title>

    <v-data-table
      v-bind="{ ...bind.table, ...tableBind }"
      @pagination="setPaginationFromTable"
    >
      <template #item.id_ramo="{ item }">
        {{
          branches?.find((e) => e.ramo == item.id_ramo)?.ramo_descripcion ??
          item.id_ramo
        }}
      </template>

      <template #item.actions="{ item }">
        <v-tooltip left color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              x-small
              text
              fab
              @click="editItem(item)"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon small> {{ icons.edit }} </v-icon>
            </v-btn>
          </template>
          <span>Editar el año {{ item.anio }}</span>
        </v-tooltip>
        <v-tooltip left color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              x-small
              text
              fab
              @click="deleteItem(item)"
              v-on="on"
              v-bind="attrs"
              :disabled="item.eliminado"
            >
              <v-icon small> {{ icons.delete }} </v-icon>
            </v-btn>
          </template>
          <span>Eliminar {{ item.anio }} del listado</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- MODAL CREAR Y EDITAR -->
    <v-dialog v-model="dialog" @click:outside="close()" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            !!selected ? "Editar Año existente" : "Crear nuevo Año"
          }}</span>

          <v-spacer></v-spacer>
          <v-btn color="primary" fab x-small text @click="close()">
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row no-gutters>
                <v-col cols="12" md="4" class="pr-2">
                  <!-- CAMPO ANIO COMBOBOX -->
                  <v-combobox
                    clearable
                    open-on-clear
                    :items="years"
                    v-model="form.anio"
                    label="Año"
                    v-mask="'####'"
                    placeholder="Seleccione un año"
                    :rules="[
                      (v) => !!v || 'Debe especificar un año.',
                      (v) =>
                        v?.toString()?.length == 4 ||
                        'El año debe tener 4 digitos',
                      (v) =>
                        /^\d+$/.test(v) || 'El año solo puede ser númerico.',
                    ]"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="8" class="pl-2">
                  <v-text-field
                    v-model="form.descripcion"
                    class="mr-1"
                    label="Descripción"
                    placeholder="Descripción del año"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="handlerSave()"
            :loading="loadingForm"
          >
            {{ selected ? "Actualizar" : "Guardar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-modal ref="confirm" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PaginationMixin from "@/Mixins/PaginationMixin.js";
import PageMixin from "@/Mixins/SetPageMixin.js";
export default {
  name: "CrudYears",
  mixins: [PageMixin, PaginationMixin],
  data: () => ({
    form: {
      descripcion: "",
      anio: new Date().getFullYear() || "",
    },
    years: [],
  }),
  computed: {
    ...mapGetters("Years", ["getItems"]),

    items() {
      return this.getItems;
    },

    headers() {
      return [
        {
          text: "Año",
          align: "start",
          value: "anio",
        },
        {
          text: "Descripción",
          align: "start",
          value: "descripcion",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ];
    },
  },
  methods: {
    ...mapActions("Years", ["GET", "POST", "PUT", "DELETE"]),

    async setup() {
      this.handlerGetItems();
      this.years = this.generateYearArray();
    },

    generateYearArray() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear + 3;
      const endYear = currentYear - 50;
      const yearArray = [];

      for (let year = startYear; year >= endYear; year--) {
        yearArray.push(year);
      }

      return yearArray;
    },

    handlerGetItems() {
      this.loading = true;
      this.GET(this.pagination)
        .then((response) => {
          this.setPaginationFromResponse(response?.pagination);
        })
        .catch((error) => {
          this.snackbar(
            error.result?.message ||
              error.info ||
              "Ha ocurrido un error al internar recuperar las materias."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlerSave() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.selected) {
          this.update();
        } else {
          this.save();
        }
      }
    },
    save() {
      this.loadingForm = true;
      this.POST(this.form)
        .then((response) => {
          const message = response.info;
          this.snackbar(message, "success");
          this.handlerGetItems();
          this.close();
        })
        .catch((error) => {
          this.snackbar(error.result?.message || error.info);
        })
        .finally(() => (this.loadingForm = false));
    },
    update() {
      this.loadingForm = true;
      const payload = {
        id: this.selected._id,
        formData: this.form,
      };
      this.PUT(payload)
        .then((response) => {
          const message = response.info;
          this.snackbar(message, "success");
          this.handlerGetItems();
          this.close();
        })
        .catch((error) => {
          this.snackbar(error.result?.message || error.info);
        })
        .finally(() => (this.loadingForm = false));
    },
    deleteItem(item = null) {
      this.$refs.confirm
        .show({
          title: "Eliminar año",
          description: "Se esta a punto de eliminar un año del listado.",
        })
        .then((answer) => {
          if (answer) {
            this.loadingForm = true;
            this.DELETE(item._id)
              .then((response) => {
                const message = response.info;
                this.snackbar(message, "primary");
                this.handlerGetItems();
              })
              .catch((error) => {
                this.snackbar(error.result?.message || error.info);
              })
              .finally(() => (this.loadingForm = false));
          }
        });
    },
    close() {
      this.dialog = false;
      this.form.descripcion = "";
      this.form.anio = new Date().getFullYear() || "";
      this.selected = null;
    },
    editItem(item) {
      this.selected = item;
      this.form.descripcion = item.descripcion;
      this.form.anio = item.anio;
      this.dialog = true;
    },
  },
};
</script>
